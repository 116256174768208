.credit-card-form-container {
    max-width: 300px;
    /*margin: 0 auto;*/
    /*padding: 20px;*/
    background: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.credit-card-paypal-container {
    max-width: 300px;
}

.credit-card-form-container h2 {
    text-align: center;
    margin-bottom: 20px;
}

.credit-card-illustration {
    /*margin-bottom: 20px;*/
    text-align: center;
}

.credit-card {
    width: 100%;
    height: 150px;
    background: #2465a1;
    color: white;
    padding: 15px;
    border-radius: 8px;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

/*.label-form {*/
/*    font-size: 0.8rem;*/
/*}*/

.card-number {
    font-size: 1.2rem;
    letter-spacing: 2px;
    direction: ltr;
}

.card-number-form {
    direction: ltr;
}

.card-expiry {
    display: flex;
    justify-content: space-between;
    width: 100%;
    font-size: 0.9rem;
    margin-bottom: 10px;
}

.card-name {
    font-size: 0.9rem;
}

.credit-card-form {
    display: flex;
    flex-direction: column;
}

.form-group {
    /*margin-bottom: 15px;*/
}

.form-group label {
    display: block;
    font-weight: bold;
    margin-top: 2px;
}

.form-control,
.amount-input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    box-sizing: border-box;
}

.form-control:focus,
.amount-input:focus {
    border-color: #007bff;
    outline: none;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.error {
    color: red;
    font-size: 0.875rem;
    margin-top: 5px;
}

.submit-button {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 10px 15px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 1rem;
}

.submit-button:hover {
    background-color: #0056b3;
}


/* containerStyle */
.containerStyle {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

/* formStyle */
.formStyle {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 250px;
    padding: 10px;
}

/* formStyle2 */
.formStyle2 {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: start;
    padding-bottom: 10px;
    padding-top: 10px;
    width: 100%;
}

/* labelStyle */
.labelStyle {
    font-size: 12px;
    text-align: right;
    direction: rtl;
    font-family: Rubik;
}

/* amountLabelStyle */
.amountLabelStyle {
    margin-top: 6%;
    font-size: 16px;
    text-align: center;
    direction: rtl;
    font-family: Rubik;
    font-weight: bold;
}

/* inputStyle */
.inputStyle {
    margin: 2% auto;
    padding: 12px;
    border: 1px solid #ccc;
    border-radius: 8px;
    width: 125%;
    height: 40px;
    direction: ltr;
}

/* inputStyle */
.inputStyle2 {
    margin: 2% auto;
    padding: 12px;
    border: 1px solid #ccc;
    border-radius: 8px;
    width: 80%;
    height: 40px;
}


/* amountStyle */
.amountStyle {
    margin: 1% auto;
    padding: 14px;
    border: 3px solid #ccc;
    border-radius: 8px;
    width: 80%;
    height: 40px;
}



/* buttonStyle */
.buttonStyle {
    font-family: Rubik;
    font-weight: bold;
    transition-duration: 0.7s;
    margin: 20px auto 0;
    height: 2.5em;
    width: 6em;
    font-size: 1.2em;
    color: white; /* Default color */
    background-color: #0f57d9; /* Default background color */
    border-radius: 7em;
    border: 0;
}

.button:hover {
    color: black;
    background-color: #ccd5fc;
}

/* labelContainerStyle */
.label-container {
    display: flex;
    flex-direction: column;
    background: linear-gradient(25deg, #fff, #eee);
    width: 250px;
}
.popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgb(255, 255, 255);
    display: flex;
    align-items: start;
    justify-content: center;
    z-index: 1000;
}

.popup-content {
    background: #fff;
    padding: 20px;
    border-radius: 5px;
    text-align: center;
}